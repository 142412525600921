import React, { useEffect, useState } from 'react';
import pkceChallenge from 'pkce-challenge';

const getLink = `${process.env.REACT_APP_IDENTITY_SERVER_URL}/Connect/Authorize?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=${process.env.REACT_APP_SCOPE}&redirect_uri=${window.location.origin}/loginesia`;

console.log({ getLink });

export const LoginEsia = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const Execute = async () => {
    if (code) {
      try {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/loginesia`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                clientId: process.env.REACT_APP_CLIENTID,
                scope: process.env.REACT_APP_SCOPE,
                identityServerUrl: process.env.REACT_APP_IDENTITY_SERVER_URL,
                redirectUrl: window.location.origin + '/loginesia',
                code: code,
                codeVerifire: localStorage.getItem('code_verifier'),
              }),
            },
          );

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          console.log('Tokens:', data); // Здесь вы можете обработать полученные токены
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    } else {
      const { code_verifier, code_challenge } = await pkceChallenge();
      console.log(
        getLink +
          `&code_challenge=${code_challenge}&code_challenge_method=S256`,
      );
      localStorage.setItem('code_verifier', code_verifier);
      window.location.replace(
        getLink +
          `&code_challenge=${code_challenge}&code_challenge_method=S256`,
      );
    }
  };

  useEffect(() => {
    console.log('useEffect called');
    Execute();
  }, []);
  return <></>; // Return null or a loading spinner while redirecting
};
